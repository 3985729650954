import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
  ADD_CLIENT,
  API_CLIENT_NAME,
  API_VARIABLE_USER_ID,
  FIELDS,
  FIELD_UPDATE_ATTRIBUTES,
  GET_AUTHORS,
  GET_CLIENTS,
  GET_GENERAL_SETTINGS,
  GET_MARKETS,
} from '../../api/api-constants';
import { AXIOS } from '../../api/axios';
import { InitializationError } from '../../common/errors';
import {
  AuthorEntity,
  ClientEntity,
  ClientServerDTO,
  FieldEntity,
  FieldUpdatePayload,
  MarketEntity,
} from '../../common/types/EntityTypes';
import {
  mapAuthorEntityToOfflineAuthorEntity,
  mapClientEntityToOfflineClientEntity,
  mapClientServerDTOToOfflineEntity,
  mapFieldEntityToOfflineFieldEntity,
  mapMarketEntityToOfflineMarketEntity,
  mapOfflineAuthorEntityToAuthorEntity,
  mapOfflineClientEntityToClientEntity,
  mapOfflineFieldEntityToFieldEntity,
  mapOfflineMarketEntityToMarketEntity,
} from '../../common/types/Mapper';
import { MetadataState } from '../../common/types/SliceTypes';
import { deleteAuthors, fetchOfflineAuthors, upsertAuthors } from '../../db/authorDBAction';
import { deleteClients, fetchOfflineClients, upsertClients } from '../../db/clientDBAction';
import { deleteField, fetchOfflineFields, upsertFields } from '../../db/fieldDBAction';
import {
  deleteMarkets,
  fetchMarkets as fetchOfflineMarkets,
  upsertMarkets,
} from '../../db/marketDBAction';
import { RootState } from '../store';
import produce from 'immer';

const initialState = {} as MetadataState;

export const metadataSlice = createSlice({
  name: 'metadata',
  initialState: initialState,
  reducers: {
    addNewClient: (state, action) => {
      const payload = action.payload;

      if (payload) {
        const clientIndex = [...state.clients].findIndex(
          (client) => client.name === payload['name'],
        );

        if (clientIndex < 0) {
          return {
            ...state,
            clients: [...state.clients, action.payload],
          };
        } else {
          return {
            ...state,
            clients: state.clients.map((c) => {
              if (c.name === action.payload['name']) {
                return action.payload;
              }
              return c;
            }),
          };
        }
      }
    },
    updateClientName: (state, action) => {
      return {
        ...state,
        clients: state.clients.map((c) => {
          if (c.viewOrder == action.payload['viewOrder']) {
            const copyClient = { ...c };
            copyClient.name = action.payload['name'];
            return copyClient;
          }
          return c;
        }),
      };
    },
    updateClientOrder: (state, action) => {
      return {
        ...state,
        clients: action.payload,
      };
    },
    setAuthors: (state, action) => {
      return {
        ...state,
        authors: action.payload,
      };
    },
    setMarkets: (state, action) => {
      return {
        ...state,
        markets: action.payload,
      };
    },
    setClients: (state, action) => {
      return {
        ...state,
        clients: action.payload.sort((a: any, b: any) => a.viewOrder - b.viewOrder),
      };
    },
    setClientName: (state, action) => {
      return {
        ...state,
        clients: state.clients.map((c) => {
          if (c.id == action.payload['id']) {
            const copyClient = { ...c };
            copyClient.name = action.payload['name'];
            return copyClient;
          }
          return c;
        }),
      };
    },
    setFields: (state, action) => {
      return {
        ...state,
        fields: action.payload,
      };
    },
    updateFields: produce((state, action) => {
      state.fields.map((field: FieldEntity) => {
        if (field.code == action.payload[0].code) {
          field.widthChange = action.payload[0].widthChange;
          field.name = action.payload[0].name;
        }
      });
    }),
    toggleFieldPin: (state, action) => {
      const currentFields = [...state.fields];
      const updatedFields = currentFields.map((field) => {
        if (field.id == action.payload) {
          const f = { ...field };
          f.pinned = !f.pinned;
          return f;
        }
        return field;
      });
      return {
        ...state,
        fields: updatedFields,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(updateFieldAttribute.fulfilled, (state, action) => {
        const updateField = action.meta.arg;
        let currentFields = [...state.fields];
        currentFields.forEach((field) => {
          if (field.code == updateField.code) {
            field.pinned = updateField.isPinned;
            field.visible = updateField.isVisible;
            field.name = updateField.name;
            field.order = updateField.order;
          }
        });
        return {
          ...state,
          fields: currentFields,
        };
      })
      .addMatcher(
        isAnyOf(fetchDefaultAuthors.pending, fetchDefaultAuthors.fulfilled),
        (state, action) => {
          // if the data is fetched from the backend update the local db
          if (action.payload != null || action.payload != undefined) {
            return {
              ...state,
              authors: action.payload,
            };
          }
        },
      )
      .addMatcher(
        isAnyOf(fetchUserMarkets.fulfilled, fetchUserMarkets.pending),
        (state, action) => {
          // if the data is fetched from the backend update the local db
          if (action.payload != null || action.payload != undefined) {
            return {
              ...state,
              markets: action.payload,
            };
          }
        },
      )
      .addMatcher(isAnyOf(fetchUserFields.pending, fetchUserFields.fulfilled), (state, action) => {
        if (action.payload != null || action.payload != undefined) {
          return {
            ...state,
            fields: action.payload,
          };
        }
      })
      .addMatcher(
        isAnyOf(fetchUserClients.pending, fetchUserClients.fulfilled),
        (state, action) => {
          if (action.payload != null || action.payload != undefined) {
            return {
              ...state,
              clients: action.payload,
            };
          }
        },
      );
  },
});

export const {
  addNewClient,
  updateClientOrder,
  setAuthors,
  setMarkets,
  setClients,
  updateFields,
  setFields,
  toggleFieldPin,
  setClientName,
  updateClientName,
} = metadataSlice.actions;

export const selectAuthors = (state: RootState) => state.metadata.authors;
export const selectMarkets = (state: RootState) => state.metadata.markets;
export const selectClients = (state: RootState) =>
  state.metadata.clients != undefined ? state.metadata.clients : [];
export const selectSelectedClient = (state: RootState) =>
  state.metadata.clients.find((c) => c.id == state.dashboard.selectedTab);
export const selectFields = (state: RootState) =>
  state.metadata.fields != undefined ? state.metadata.fields : [];
export const selectFieldLength = (state: RootState) =>
  state.metadata.fields != undefined ? state.metadata.fields.length : 28;
export const selectFieldByCode = (state: RootState, fieldCode: string) => {
  if (state.metadata.fields != undefined) {
    state.metadata.fields.find((field) => field.code == fieldCode);
  }
};

export default metadataSlice.reducer;

/** API Calls for the data */
export const fetchDefaultAuthors = createAsyncThunk('authors', async () => {
  let authorsOffline = await fetchOfflineAuthors();
  let authors: AuthorEntity[] = authorsOffline.map((a) => mapOfflineAuthorEntityToAuthorEntity(a));

  const response = await AXIOS.get(GET_AUTHORS);

  if (authors == undefined || authors.length == 0) {
    if (response.status == 200) {
      authors = response.data.data;
      await upsertAuthors(
        authors.map((author: AuthorEntity) => mapAuthorEntityToOfflineAuthorEntity(author, true)),
      );
    } else {
      throw new InitializationError('Authors not available.');
    }
  } else {
    if (response.status == 200) {
      const serverResponse: AuthorEntity[] = response.data.data;

      // In future when we require to maintain custom author the non persisted changes have to be pushed back
      const nonPersistedAuthors = authorsOffline.filter((author) => !author.isPersisted);

      const commonAuthors = _.intersectionBy(authors, serverResponse, 'externalId');

      // Find if there are any records from the backend and add them
      const newRecords = _.differenceBy(serverResponse, authors, 'externalId');
      await upsertAuthors(
        newRecords.map((author: AuthorEntity) =>
          mapAuthorEntityToOfflineAuthorEntity(author, true),
        ),
      );

      // Find the records which are present in the UI but are not required as per the backend
      const oldRecords = _.differenceBy(authors, serverResponse, 'externalId');
      await deleteAuthors(oldRecords.map((author) => author.externalId));

      authors = [...commonAuthors, ...newRecords];
    }
  }

  return authors;
});

export const fetchUserMarkets = createAsyncThunk(
  '/user/markets',
  async (userId: string, { rejectWithValue }) => {
    if (userId) {
      let marketsOffline = await fetchOfflineMarkets();
      let markets = marketsOffline.map((m) => mapOfflineMarketEntityToMarketEntity(m));

      const response = await AXIOS.get(GET_MARKETS + '/' + userId);

      if (markets == undefined || markets.length == 0) {
        if (response.status == 200) {
          markets = response.data.data.sort((a: any, b: any) => {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });
          await upsertMarkets(
            markets.map((market: MarketEntity) =>
              mapMarketEntityToOfflineMarketEntity(market, true),
            ),
          );
        } else {
          throw new InitializationError('Markets not available.');
        }
      } else {
        if (response.status == 200) {
          const serverResponse: MarketEntity[] = response.data.data;

          // In future when we require to maintain custom market the non persisted changes have to be pushed back
          const nonPersistedMarkets = marketsOffline.filter((market) => !market.isPersisted);

          const commonMarkets = _.intersectionBy(serverResponse, markets, 'externalId');

          // Find if there are any records from the backend and add them
          const newMarkets = _.differenceBy(serverResponse, markets, 'externalId');
          await upsertMarkets(
            newMarkets.map((market: MarketEntity) =>
              mapMarketEntityToOfflineMarketEntity(market, true),
            ),
          );

          // Find the records which are present in the UI but are not required as per the backend
          const oldMarkets = _.differenceBy(serverResponse, markets, 'externalId');
          await deleteMarkets(oldMarkets.map((author) => author.externalId));

          markets = [...commonMarkets, ...newMarkets].sort((a, b) => {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });
        }
      }

      return markets;
    }
  },
);

export const fetchUserSettings = createAsyncThunk(
  '/user/settings',
  async (userId: string, { rejectWithValue }) => {
    if (userId) {
      const response = await AXIOS.get(GET_GENERAL_SETTINGS + '/' + userId);
      if (response.status == 200) {
        const serverResponse: any[] = response.data.data;
        return serverResponse;
      }
    }
  },
);
export const fetchUserClients = createAsyncThunk('/user/clients', async (id: string) => {
  let clientsOffline = await fetchOfflineClients();
  let clients = clientsOffline.map((c) => mapOfflineClientEntityToClientEntity(c));

  const url = GET_CLIENTS.replace(API_VARIABLE_USER_ID, id);
  const response = await AXIOS.get(url);
  if (clients == undefined || clients.length == 0) {
    if (response.status == 200) {
      const serverDTO: ClientServerDTO[] = response.data.data;
      clientsOffline = serverDTO.map((client) => mapClientServerDTOToOfflineEntity(client, true));
      upsertClients(clientsOffline);

      const mappedClients: ClientEntity[] = serverDTO.map((client) => {
        return {
          id: client.externalId,
          custom: client.custom,
          name: client.name,
          viewOrder: client.viewOrder,
          default: client.default,
          alias: [],
        };
      });
      clients = mappedClients;
    } else {
      throw new InitializationError('User Clients/Tab not available.');
    }
  } else {
    if (response.status == 200) {
      const serverResponse: ClientServerDTO[] = response.data.data;

      const serverClients: ClientEntity[] = serverResponse.map((client) => {
        return {
          id: client.externalId,
          custom: client.custom,
          name: client.name,
          viewOrder: client.viewOrder,
          default: client.default,
          alias: [],
        };
      });

      // In future when we require to maintain custom market the non persisted changes have to be pushed back
      const nonPersistedClients = clientsOffline.filter((client) => !client.isPersisted);

      const commonClients = _.intersectionBy(clients, serverClients, 'id');

      // Find if there are any records from the backend and add them
      const newClients = _.differenceBy(serverClients, clients, 'id');
      upsertClients(newClients.map((client) => mapClientEntityToOfflineClientEntity(client, true)));

      // Find the records which are present in the UI but are not required as per the backend
      const oldClients = _.differenceBy(clients, serverClients, 'id');
      await deleteClients(oldClients.map((client) => client.id));

      clients = [...commonClients, ...newClients];
    }
  }
  return clients;
});

export const addClientToDB = createAsyncThunk(
  '/user/client/add',
  async (clientName: string, { getState }) => {
    const s = getState() as RootState;
    if (clientName) {
      const url = ADD_CLIENT.replace(API_CLIENT_NAME, clientName).replace(
        API_VARIABLE_USER_ID,
        s.user.externalId,
      );

      const response = await AXIOS.post(url);

      // we rely on the events now to make sure that the data is added
      if (response.status != 200) {
        console.log('Error Occured');
      }
    }
  },
);

export const fetchUserFields = createAsyncThunk('/fields', async (id: string) => {
  if (id) {
    try {
      let fieldsOffline = await fetchOfflineFields();
      let fields = fieldsOffline.map((f) => mapOfflineFieldEntityToFieldEntity(f));

      const url = FIELDS + id;
      const response = await AXIOS.get(url);
      if (fields == undefined || fields.length == 0) {
        if (response.status == 200) {
          fields = response.data.data;
          await upsertFields(
            fields.map((field: FieldEntity) => mapFieldEntityToOfflineFieldEntity(field, true)),
          );
        } else {
          throw new InitializationError('Fields not available.');
        }
      } else {
        if (response.status == 200) {
          const serverResponse: FieldEntity[] = response.data.data;

          const commonColumn = _.intersectionBy(serverResponse, fields, 'id');
          // Find if there are any records from the backend and add them
          const newColumns = _.differenceBy(serverResponse, fields, 'id');
          await upsertFields(
            newColumns.map((field: FieldEntity) => mapFieldEntityToOfflineFieldEntity(field, true)),
          );

          // Find the records which are present in the UI but are not required as per the backend
          const oldColumns: FieldEntity[] = _.differenceBy(fields, serverResponse, 'id');
          await deleteField(oldColumns.map((client) => client.id));

          fields = [...commonColumn, ...newColumns];
        }
      }

      return fields;
    } catch (error) {
      console.log(error);
    }
  }
});

export const updateFieldAttribute = createAsyncThunk(
  '/field/attribute',
  async (requestPayload: FieldUpdatePayload, { getState }) => {
    try {
      const state = getState() as RootState;
      let url = FIELD_UPDATE_ATTRIBUTES.replace(API_VARIABLE_USER_ID, state.user.externalId);
      const response = await AXIOS.post(url, requestPayload);
      if (response) {
        return response.data;
      }
    } catch (err) {
      console.error(err);
    }
  },
);
