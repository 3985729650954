import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { FieldValueMetadata } from '../../../../common/types/dashboard/DashboardUITypes';
import { upsertFieldValues } from '../../../../db/fieldDBAction';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { selectSelectedDocumentId } from '../../../../redux/reducers/dashboardSlice';
import './EditableValue.scss';
import { selectUserAppFontSize } from '../../../../redux/reducers/settingsSlice';
import { selectCustomLineHeight } from '../../../../redux/reducers/uiSlice';
import { updateDocumentFieldValue } from '../../../../redux/thunks';

function EditableValue(props: { field: FieldValueMetadata; isSelected: boolean }) {
  const selectedDocumentId = useAppSelector(selectSelectedDocumentId);
  const dispatch = useAppDispatch();
  const field = { ...props.field };
  const initialValue = field.value;
  const [isEditable, setIsEditable] = useState(false);
  const [fieldNewValue, setFieldNewValue] = useState(field.value);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const handleKeyDown = (event: any) => {
    event.target.style.height = 'auto';
    event.target.style.height = `${event.target.scrollHeight}px`;
    const newValue = event.target.value;
    if (event.key === 'Escape') {
      setIsEditable(false);
      setFieldNewValue(field.value);
    } else if (event.key === 'Enter' && newValue !== '') {
      handleChange(newValue);
      setIsEditable(false);
    }
  };

  const handleChange = (newValue: string) => {
    upsertFieldValues({
      docId: selectedDocumentId,
      isPushed: false,
      fieldId: field.field,
      newValue: newValue,
      oldValue: field.value,
      isPersisted: false,
    }).then((res) => {
      dispatch(
        updateDocumentFieldValue({
          docId: selectedDocumentId,
          fieldCode: field.field,
          newValue: newValue,
          oldValue: field.value,
        }),
      );
    });

    setFieldNewValue(newValue);
    field.prevValue = initialValue;
    field.value = newValue;
  };

  const handleDocumentClick: EventListener = async (event) => {
    if (
      textareaRef.current &&
      event.target instanceof Node &&
      !textareaRef.current.contains(event.target)
    ) {
      // Set the textarea to non-editable when clicked outside
      setIsEditable(false);
      if (initialValue != fieldNewValue) {
        await upsertFieldValues({
          docId: selectedDocumentId,
          isPushed: false,
          fieldId: field.field,
          newValue: fieldNewValue,
          oldValue: field.value,
          isPersisted: false,
        });

        field.prevValue = field.value;
        field.value = fieldNewValue;
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  const userFontSize = Number(useAppSelector(selectUserAppFontSize).value);
  const userLineHeight = useAppSelector(selectCustomLineHeight);
  const customStyle = {
    cursor: 'pointer',
    height: 'auto',
    fontStyle: 'normal',
    '--custom-text-font-size': userFontSize + 'px',
    '--custom-line-height': userLineHeight,
  };

  return (
    <>
      <div
        style={customStyle}
        className={classNames('value', {
          hide: isEditable,
          'value-edited': initialValue != fieldNewValue,
          'value-selected': props.isSelected,
          'value-not-selected': !props.isSelected,
        })}
        onDoubleClick={() => setIsEditable(true)}>
        {fieldNewValue}
      </div>
      <textarea
        ref={textareaRef}
        className={classNames('value', {
          hide: !isEditable,
        })}
        onChange={(event) => setFieldNewValue(event.target.value)}
        onKeyDown={handleKeyDown}
        value={fieldNewValue}></textarea>
    </>
  );
}
export default EditableValue;
