import {
  CellClickedEvent,
  CellDoubleClickedEvent,
  ColDef,
  ColumnResizedEvent,
  GetRowIdFunc,
  GetRowIdParams,
  IRowNode,
  RowDoubleClickedEvent,
  RowHeightParams,
} from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import { AgGridReact } from 'ag-grid-react';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  CLIENT_DUMMY_ROW_IDS,
  CUSTOM_COLUMN_KEY,
  LEASE_STATUS,
  NONE_MENU_ID,
} from '../../../common/constants';
import { CustomDashboardParams } from '../../../common/types/dashboard/DashboardUITypes';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';

import _ from 'lodash';
import { DocumentEntity, FieldEntity } from '../../../common/types/EntityTypes';
import { updateDocumentReadStatus } from '../../../db/documentDBAction';
import {
  fetchDocumentHTML,
  selectCurrentTab,
  selectIsDocumentDeleted,
  selectSelectedDocumentExpandedId,
  selectSelectedDocumentId,
  setDocumentDeleted,
  setSelectedDocument,
  updateFieldResize,
  updateRowReadStatus,
} from '../../../redux/reducers/dashboardSlice';
import {
  markDocumentRead,
  selectChildrenForParent,
  selectRows,
} from '../../../redux/reducers/documentsDataSlice';
import { selectLeaseStatus } from '../../../redux/reducers/leaseStatusSlice';
import { selectFields } from '../../../redux/reducers/metadataSlice';
import { setAbstraction, setMenu } from '../../../redux/reducers/popupSlice';
import {
  selectCurrentHeight,
  selectFontPer,
  selectResolutionMetadata,
  selectTableFontSize,
  selectCustomActiveRowHeight,
  selectCustomInActiveRowHeight,
  selectCustomCellPadding,
  selectCustomLineHeight,
} from '../../../redux/reducers/uiSlice';
import './Table.scss';
import TableCell from './table-cell/TableCell';
import TableHeader from './table-header/TableHeader';
import { updateDocumentStatusForReadStatus } from '../../../redux/reducers/documentsStatusSlice';
import { getUpdatedCustomFontSize } from '../../../common/util';
import {
  selectParentsWithChildren,
  setParentsWithChildren,
} from '../../../redux/reducers/documentsSlice';
import {
  selectUserTableFontSize,
} from '../../../redux/reducers/settingsSlice';

// Images import

function Table(props: CustomDashboardParams) {
  const gridRef = useRef<AgGridReact<DocumentEntity>>(null);
  const uiTableFontSize = useAppSelector(selectTableFontSize);
  
  //Font change Per
  const fontChangePer = useAppSelector(selectFontPer);
  const resMetadata = useAppSelector(selectResolutionMetadata);
  const userActiveRowHeight = Number(useAppSelector(selectCustomActiveRowHeight));
  const userInActiveRowHeight = Number(useAppSelector(selectCustomInActiveRowHeight));
  const userCellPadding = Number(useAppSelector(selectCustomCellPadding));
  const userLineHeight = Number(useAppSelector(selectCustomLineHeight));
  const userTableFontSize = Number(useAppSelector(selectUserTableFontSize).value);
  const currentScreenHeight = useAppSelector(selectCurrentHeight);

  const userActiveLine = useAppSelector(selectCustomActiveRowHeight);
  const userInActiveLine = useAppSelector(selectCustomInActiveRowHeight);

  // Selected Tab
  const selectedTab = useAppSelector(selectCurrentTab);
  const isDocumentDeleted = useAppSelector(selectIsDocumentDeleted);

  // Headers Selection
  const headers = useAppSelector(selectFields);
  // Rows App Selector
  const rows = useAppSelector(selectRows);
  const selectedDocumentId = useAppSelector(selectSelectedDocumentId);
  // Children For Current Selected Row
  const children = useAppSelector(selectChildrenForParent);
  const expandedParent = useAppSelector(selectSelectedDocumentExpandedId);

  // Children master details
  const parentsWithChildren = useAppSelector(selectParentsWithChildren);
  const leaseStatusFilters = useAppSelector(selectLeaseStatus);
  const dispatch = useAppDispatch();
  const customStyle = {
    height: currentScreenHeight < 900 ? 'calc(100% - 3.5rem)' : 'calc(100% - 4.75rem)',
    '--custom-font-size': uiTableFontSize + 'px',
    '--custom-line-height': userLineHeight,
    '--custom-cell-padding': userCellPadding + 'px',
    '--custom-dashboard-author-font-size': getUpdatedCustomFontSize(
      '--custom-dashboard-author-font-size',
    ),
  };

  const getRowHeight = useCallback(
    (params: RowHeightParams) => {
      if (params.data.locked) {
        return userActiveLine * userTableFontSize * userLineHeight  + userCellPadding * 2;
      }
      return userInActiveLine * userTableFontSize * userLineHeight  + userCellPadding * 2;
    },
    [fontChangePer],
  );
  //  Expanding row height for one always
  useEffect(() => {
    if ((!_.isEmpty(rows) && isDocumentDeleted) || selectedDocumentId === undefined) {
      gridRef.current?.api?.forEachNode(function (rowNode) {
        if (rowNode.data?.id === transformedRowsData[0].id) {
          rowNode.setRowHeight(userActiveLine * userTableFontSize * userLineHeight  + userCellPadding * 2);
          rowNode.setSelected(true);
          gridRef.current?.api.onRowHeightChanged();
          dispatch(setSelectedDocument(rowNode.data));
          dispatch(setDocumentDeleted(false));
        } 
        if (!rowNode.data?.locked && !rowNode.isSelected() && userInActiveRowHeight > 0) {
          rowNode.setRowHeight(userInActiveLine * uiTableFontSize * userLineHeight + userCellPadding * 2);
        }
      });
    }
  }, [isDocumentDeleted, rows]);

  // Resizing Row Height
  useEffect(() => {
    gridRef?.current?.api?.forEachNode(function (rowNode) {
      if (!rowNode.data?.locked && !rowNode.isSelected() && userInActiveRowHeight > 0) {
        rowNode.setRowHeight(userInActiveLine * uiTableFontSize * userLineHeight + userCellPadding * 2);
      } else if (rowNode.data?.locked || (rowNode.isSelected() && userActiveRowHeight > 0)) {
        rowNode.setRowHeight(userActiveLine * uiTableFontSize * userLineHeight + userCellPadding * 2);
      }
    });
    const headerLines = uiTableFontSize > 18 ? 2.75 : 2;
    gridRef?.current?.api?.setHeaderHeight(headerLines * uiTableFontSize * userLineHeight + userCellPadding * 2);
    gridRef?.current?.api?.onRowHeightChanged();
  }, [userInActiveRowHeight, userActiveRowHeight, userCellPadding, uiTableFontSize, rows, expandedParent]);

  const getColumnWidth = (headerKey: String) => {
    switch (headerKey) {
      case CUSTOM_COLUMN_KEY.PIN:
        return resMetadata.columns_dimension.PIN.widthPX;
      case CUSTOM_COLUMN_KEY.AUTHOR:
        return resMetadata.columns_dimension.AUTHOR.widthPX;
      case CUSTOM_COLUMN_KEY.STATUS:
        return resMetadata.columns_dimension.STATUS.widthPX;
      case CUSTOM_COLUMN_KEY.MARKET:
        return resMetadata.columns_dimension.MARKET.widthPX;
      case CUSTOM_COLUMN_KEY.LOI_DATE_KEY:
        return resMetadata.columns_dimension.LOI_DATE.widthPX;
      case CUSTOM_COLUMN_KEY.STATE:
        return resMetadata.columns_dimension.STATE.widthPX;
      case CUSTOM_COLUMN_KEY.CITY:
        return resMetadata.columns_dimension.MARKET.widthPX;
      case CUSTOM_COLUMN_KEY.TRADE_AREA:
        return resMetadata.columns_dimension.MARKET.widthPX;
      case CUSTOM_COLUMN_KEY.TENANT_ENTITY:
        return resMetadata.columns_dimension.MARKET.widthPX;
      case CUSTOM_COLUMN_KEY.TENANT:
        return resMetadata.columns_dimension.MARKET.widthPX;
      case CUSTOM_COLUMN_KEY.LANDLORD_ENTITY:
        return resMetadata.columns_dimension.MARKET.widthPX;
      case CUSTOM_COLUMN_KEY.PROJECT:
        return resMetadata.columns_dimension.MARKET.widthPX;
      case CUSTOM_COLUMN_KEY.ADDRESS:
        return resMetadata.columns_dimension.MARKET.widthPX + 20;
      case CUSTOM_COLUMN_KEY.SUITE:
        return resMetadata.columns_dimension.SUITE.widthPX;
      case CUSTOM_COLUMN_KEY.SIZE:
        return resMetadata.columns_dimension.SUITE.widthPX;
      case CUSTOM_COLUMN_KEY.BASE_RENT:
        return resMetadata.columns_dimension.SUITE.widthPX;
      case CUSTOM_COLUMN_KEY.TIA:
        return resMetadata.columns_dimension.SUITE.widthPX;
      case CUSTOM_COLUMN_KEY.OPTIONS:
        return resMetadata.columns_dimension.MARKET.widthPX;
      case CUSTOM_COLUMN_KEY.TERM:
        return resMetadata.columns_dimension.MARKET.widthPX;
      case CUSTOM_COLUMN_KEY.NNNN_ESTIMATE:
        return resMetadata.columns_dimension.STATE.widthPX - 70;
      case CUSTOM_COLUMN_KEY.RET:
        return resMetadata.columns_dimension.SUITE.widthPX;
      case CUSTOM_COLUMN_KEY.CAM:
        return resMetadata.columns_dimension.SUITE.widthPX;
      case CUSTOM_COLUMN_KEY.INS:
        return resMetadata.columns_dimension.SUITE.widthPX;
      case CUSTOM_COLUMN_KEY.DELIVERY_DATE:
        return resMetadata.columns_dimension.MARKET.widthPX;
      case CUSTOM_COLUMN_KEY.RENT_ABATEMENT:
        return resMetadata.columns_dimension.MARKET.widthPX;
      case CUSTOM_COLUMN_KEY.PERCENTAGE_RENT:
        return resMetadata.columns_dimension.MARKET.widthPX;
      case CUSTOM_COLUMN_KEY.NOTES:
        return resMetadata.columns_dimension.NOTES.widthPX;
      case CUSTOM_COLUMN_KEY.NOTES_HIDDEN:
        return resMetadata.columns_dimension.NOTES_HIDDEN.widthPX;
      case CUSTOM_COLUMN_KEY.STATUS_HIDDEN:
        return resMetadata.columns_dimension.STATUS_HIDDEN.widthPX;
      case CUSTOM_COLUMN_KEY.CHILDREN:
        return resMetadata.columns_dimension.CHILDREN.widthPX;
      case CUSTOM_COLUMN_KEY.DOCUMENT_NAME:
        return resMetadata.columns_dimension.MARKET.widthPX;
      default:
        return resMetadata.columns_dimension.OTHERS.widthPX;
    }
  };

  const transformedHeadersDef = useMemo(() => {
    if (headers.length > 0) return transformHeaders(headers);
  }, [headers, selectedDocumentId]);

  const transformedRowsData = useMemo(() => {
    const clientsWithData = Object.keys(rows);

    const dummyRowsFromIndex = localStorage.getItem(CLIENT_DUMMY_ROW_IDS);

    let data: DocumentEntity[] = [];
    if (selectedTab != undefined && !_.isEmpty(rows)) {
      if (selectedTab in rows) data = rows[selectedTab];
      // data = rows[selectedTab].filter((row) => {
      //   return row.parent == '' || expandedParent.includes(row.parent);
      // });
    }

    // if data is present and selected tab has no data available
    // let data: DocumentEntity[] = [];
    // if (
    //   clientsWithData.length == 0 ||
    //   clientsWithData.indexOf(selectedTab) < 0
    // ) {
    //   data = [];
    // } else {
    //   data = rows[selectedTab].filter((row) => {
    //     return row.parent == "" || expandedParent.includes(row.parent);
    //   });
    // }

    // if (dummyRowsFromIndex != null) {
    //   const dummyClientData = JSON.parse(dummyRowsFromIndex)[selectedTab];
    //   if (dummyClientData) {
    //     const dummyRows = dummyClientData.slice(0, 50 - data.length);
    //     data = [...data, ...dummyRows];
    //   }
    // }
    // Sort the array in descending order based on the 'ts' property
    const sortedData =
      data && [...data].sort((a, b) => new Date(b.ts).getTime() - new Date(a.ts).getTime());

    // Create a mapping to store children for each parent
    const childrenMap: Record<string, any[]> = {};
    sortedData &&
      sortedData.forEach((item) => {
        if (item.parent) {
          if (!childrenMap[item.parent]) {
            childrenMap[item.parent] = [];
          }
          childrenMap[item.parent].push(item);
        }
      });

    // Rearrange the array by grouping parents and their children
    const arrangedData: any[] = [];
    sortedData &&
      sortedData.forEach((item) => {
        if (!item.parent) arrangedData.push(item);
        if (childrenMap[item.id] && expandedParent.includes(item.id)) {
          // Add children sorted by ts in descending order
          arrangedData.push(
            ...childrenMap[item.id].sort(
              (a, b) => new Date(b.ts).getTime() - new Date(a.ts).getTime(),
            ),
          );
        }
      });

    return arrangedData;
  }, [rows, selectedTab, expandedParent]);

  // useEffect(() => {
  //   if (rows[selectedTab]) {
  //     gridRef.current!.api.setRowData(rows[selectedTab]);
  //     gridRef.current!.api.redrawRows();
  //   }
  // }, [selectedTab]);

  useEffect(() => {
    if (selectedTab != undefined && !_.isEmpty(rows))
      if (selectedTab in rows)
        rows[selectedTab] &&
          rows[selectedTab].forEach((doc) => {
            if (doc.parent !== '') {
              rows[selectedTab].map((el) => {
                if (el.id === doc.parent) {
                  if (!parentsWithChildren.includes(doc.parent)) {
                    dispatch(setParentsWithChildren([...parentsWithChildren, doc.parent]));
                  }
                }
              });
            }
          });
  }, [selectedTab, rows]);

  const components = useMemo(
    () => ({
      agColumnHeader: TableHeader,
    }),
    [],
  );

  function transformHeaders(headers: FieldEntity[]): ColDef[] {
    // Prepare the Column Defination
    function prepareColDef(header: FieldEntity) {
      // if (header.code == CUSTOM_COLUMN_KEY.AUTHOR && header.code == CUSTOM_COLUMN_KEY.TRADE_AREA && header.code == CUSTOM_COLUMN_KEY.MARKET) {
      //   return
      // }
      // Default Column Def
      let colDef: ColDef = {
        colId:
          header.code === CUSTOM_COLUMN_KEY.NOTES_HIDDEN
            ? CUSTOM_COLUMN_KEY.NOTES_HIDDEN
            : header.name,
        headerName: '',
        pinned: isColumnPinnedAndLocked(header.pinned, header.order),
        headerComponentParams: {
          customData: header,
        },
        headerClass: (params) => {
          const classNames = [];
          // if (header.order < 0 || header.order == 100 || header.order == 101) {
          if (header.order == 102) {
            classNames.push('setting-column-color');
          } else if (isColumnPinnedAndLocked(header.pinned, header.order) != null) {
            classNames.push('pinned-column-header');
          } else {
            classNames.push('normal-column-color');
          }
          return classNames;
        },
        wrapHeaderText: true,
        wrapText: true,
        cellClass: (params) => {
          const classNames: string[] = [];
          if (params.data.id == selectedDocumentId) {
            classNames.push('selected-row-color');
          } else {
            if (header.order < 0 || header.order == 100 || header.order == 101) {
              classNames.push('pinned-column-cell-color');
            } else if (header.order == 0) {
              classNames.push('market-column');
            }
          }
          if (header.code == CUSTOM_COLUMN_KEY.NOTES_HIDDEN) {
            classNames.push(CUSTOM_COLUMN_KEY.NOTES_HIDDEN);
          }
          return classNames;
        },
        suppressMovable: header.order < 0 && header.order >= 100 ? true : false,
        resizable: header.order !== 102 ? true : false,
        width: getColumnWidth(header.code) + getColumnWidth(header.code) * header.widthChange,
        lockPosition: header.pinned
          ? header.order < 0
            ? 'left'
            : header.order > 100
            ? 'right'
            : undefined
          : undefined,
        initialHide:
          header.code == CUSTOM_COLUMN_KEY.NOTES_HIDDEN ||
          header.code == CUSTOM_COLUMN_KEY.STATUS_HIDDEN
            ? true
            : false,
        cellRenderer: TableCell,
        cellRendererParams: { customData: header },
      };

      // if (header.order < 0) {
      //   colDef["lockPosition"] = "left";
      // } else if (header.order >= 100) {
      //   colDef["lockPosition"] = "right";
      // }

      // colDef["cellRenderer"] = TableCell;
      // colDef["cellRendererParams"] = { customData: header };

      // if (header.code === 'trade') {
      //   colDef['editable'] = true;
      // }

      return colDef;
    }

    function isColumnPinnedAndLocked(customPinned: boolean, order: number) {
      if (order < 0 || customPinned) {
        return 'left';
      } else if (order >= 100) {
        return 'right';
      }
      return null;
    }

    const tempHeaders = headers.filter(
      (e) =>
        ![
          CUSTOM_COLUMN_KEY.AUTHOR,
          CUSTOM_COLUMN_KEY.MARKET,
          CUSTOM_COLUMN_KEY.TRADE_AREA,
        ].includes(e.code),
    );

    return [...tempHeaders]
      .sort((h1, h2) => h1.order - h2.order)
      .map((header) => prepareColDef(header));
  }

  // function prepareRows(rows: DType[], filterBy: string) {
  function prepareRows(rows: DocumentEntity[]) {
    let fieldValues: DocumentEntity[] = rows;
    return fieldValues;
  }

  const getRowId = useMemo<GetRowIdFunc>(() => {
    return (params: GetRowIdParams<DocumentEntity>) => params.data.id;
  }, []);

  const isRowSelectable = (rowData: IRowNode<DocumentEntity>) => {
    return rowData.data?.status != LEASE_STATUS.DUMMY;
  };

  const handleCellClick = (event: CellClickedEvent) => {
    const restrictiveFieldCodes = ['Children'];
    // [
    //   "Author",
    //   "LOI Status",
    //   "Market",
    //   "Children",
    //   "",
    // ];

    // if (event.column?.getColId() === 'LOI Status') {
    //   return;
    // }
    const headersName = headers
      .filter((header) => restrictiveFieldCodes.indexOf(header.name) >= 0)
      .map((header) => header.name);

    if (event.colDef.colId && headersName.indexOf(event.colDef.colId) >= 0) {
      if (event.colDef.colId === 'Children') {
        return;
      } else if (event.colDef.colId === 'LOI Status') {
        // displayMenu(event, event.node.data, MENU_SUB_TYPE.AUTHOR);
      }
    } else {
      gridRef.current?.api.forEachNode(function (rowNode) {
        if (rowNode.data?.id && rowNode.isSelected()) {
          if (!rowNode.data.read) {
            updateDocumentReadStatus(rowNode.data?.id, true, false)?.then(() => {
              dispatch(
                markDocumentRead({
                  clientId: selectedTab,
                  documentId: rowNode.data?.id,
                  status: true,
                }),
              );
              dispatch(
                updateDocumentStatusForReadStatus({
                  documentId: rowNode.data?.id,
                  isRead: true,
                }),
              );
              dispatch(updateRowReadStatus(true)).then(() =>
                updateDocumentReadStatus(rowNode.data?.id, true, false),
              );
            });
          }
          rowNode.setRowHeight(userActiveLine * userTableFontSize * userLineHeight + userCellPadding * 2);
        } else {
          if (!rowNode.data?.locked)
            rowNode.setRowHeight(userInActiveLine * userTableFontSize * userLineHeight + userCellPadding * 2);
        }
      });
      event.api.onRowHeightChanged();
      dispatch(setSelectedDocument(event.data));
    }
  };

  // handle the row selection
  // const handleRowSelection = (event: RowSelectedEvent) => {
  //   gridRef.current?.api.forEachNode(function (rowNode) {
  //     if (rowNode.data?.id && rowNode.isSelected()) {
  //       rowNode.setRowHeight(
  //         resMetadata.headerLineHeight * 10 + userCellPadding * 2
  //       );
  //     } else {
  //       if (!rowNode.data?.locked) rowNode.setRowHeight(uiInactiveRowHeight);
  //     }
  //   });
  //   event.api.onRowHeightChanged();
  //   dispatch(setSelectedDocument(event.data));
  // };

  // Show abstraction on the double click
  // const handleRowDoubleClick = useCallback((event: RowDoubleClickedEvent) => {
  //   dispatch(setSelectedDocument(event.data));
  //   dispatch(setMenu(NONE_MENU_ID));
  //   dispatch(setAbstraction());
  //   dispatch(fetchDocumentHTML());
  // }, []);

  const handleCellDoubleClick = useCallback((event: CellClickedEvent) => {
    if (event.colDef.colId === 'Children') {
      return;
    }
    dispatch(setSelectedDocument(event.data));
    dispatch(setMenu(NONE_MENU_ID));
    dispatch(setAbstraction());
    dispatch(fetchDocumentHTML());
  }, []);

  // const handleColumnDrag = useCallback((event: ColumnMovedEvent) => {
  //   if (event.finished) console.log(event);
  // }, []);

  const isExternalFilterPresent = useCallback(() => {
    return true;
  }, []);

  const doesExternalFilterPass = useCallback(
    (node: IRowNode<DocumentEntity>) => {
      const appliedFilters = leaseStatusFilters
        .filter((filter) => filter.selected)
        .map((filter) => filter.name);
      if (node.data) {
        const lStatus = node.data.status;
        if (appliedFilters.includes(lStatus)) {
          return true;
        }
      }
      return false;
    },
    [leaseStatusFilters],
  );

  useEffect(() => {
    gridRef.current?.api?.onFilterChanged();
  }, [leaseStatusFilters]);

  const scrollWidth = document.getElementsByClassName('ag-body-vertical-scroll')[0]?.clientWidth
  // Aligining Deal Notes to factor customized scroll design
  useEffect(() => {
    window.addEventListener('resize', handleScrollSizeChange);
    return () => {
      window.removeEventListener('resize', handleScrollSizeChange);
    };
  }, [rows, scrollWidth]);

  const handleScrollSizeChange = () => {
    setTimeout(() => {
      const width = document.getElementsByClassName('ag-body-vertical-scroll')[0]?.clientWidth || 0;
      var pinnedRightHeader = document.querySelector('.ag-pinned-right-header');
      if (width > 0) {
        pinnedRightHeader?.classList.add('ag-pinned-right-header-with-scroll');
      } else {
        pinnedRightHeader?.classList.remove('ag-pinned-right-header-with-scroll');
      }
    }, 250);
  };

  const getRowClass = (params: any) => {
    let rowClasses = '';
    if (params.data.parent !== '') {
      rowClasses += ' row-child-lease ';
    }
    return rowClasses;
  };

  // const onGridReady = useCallback((params: GridReadyEvent) => {
  //   addDropZones(params);
  // }, []);

  // function addDropZones(params: GridReadyEvent) {
  //   tabs.forEach((element, index) => {
  //     if (element.default) {
  //       return;
  //     }
  //     var tabHolder = document.querySelector(`#tabDiv-${element.id}`) as any;
  //     var dropZone: RowDropZoneParams = {
  //       getContainer: () => {
  //         return tabHolder as any;
  //       },
  //       onDragEnter: (params) => {
  //         tabHolder.classList.add('drop-target-highlight');
  //       },
  //       onDragLeave: (params) => {
  //         tabHolder.classList.remove('drop-target-highlight');
  //       },
  //       onDragStop: (params) => {
  //         console.log(element.name);
  //         dispatch(
  //           updateClientForDocumentAPI({
  //             clientId: element.id,
  //             documentId: params?.node?.data?.id,
  //           }),
  //         ).then((res) => {
  //           if (res) {
  //             const clientIds: string[] = tabs.map((obj) => obj.id);
  //             dispatch(
  //               updateClientForDocument({
  //                 sourceClientId: params?.node?.data.client,
  //                 targetClientId: element.id,
  //                 documentId: params?.node?.data.id,
  //                 leaseDoc: params?.node?.data,
  //               }),
  //             );
  //             setTimeout(() => {
  //               dispatch(fetchUserClientsDocuments({ clientIds, userId })).then((res) => {
  //                 if (res?.payload?.data) {
  //                   dispatch(setDocuments(res?.payload?.data));
  //                 }
  //               });
  //             }, 1000);
  //           }
  //         });
  //         tabHolder.classList.remove('drop-target-highlight');
  //       },
  //     };
  //     params.api.addRowDropZone(dropZone);
  //   });
  // }
  let resizeTimer: ReturnType<typeof setTimeout>;
  const handleColResize = (event: ColumnResizedEvent) => {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(() => {
      onColumnResized(event);
    }, 300);
  };

  function onColumnResized(event: ColumnResizedEvent) {
    let fieldEntity: FieldEntity = event.column?.getColDef().cellRendererParams.customData;
    let widthChange =
      ((event.column?.getActualWidth() || 0) - getColumnWidth(fieldEntity?.code)) /
      getColumnWidth(fieldEntity?.code);
    dispatch(updateFieldResize({ ...fieldEntity, widthChange }));
  }

  return (
    <>
      <div className='ag-theme-alpine' style={customStyle}>
        <AgGridReact
          // onColumnMoved={handleColumnDrag}
          ref={gridRef}
          // onGridReady={onGridReady}
          components={components}
          columnDefs={transformedHeadersDef}
          rowData={transformedRowsData}
          getRowHeight={getRowHeight}
          getRowClass={getRowClass}
          headerHeight={2 * userTableFontSize * userLineHeight + userCellPadding * 2}
          rowSelection='single'
          getRowId={getRowId}
          // rowDragManaged={selectTabInfo?.default}
          animateRows={true}
          isRowSelectable={isRowSelectable}
          suppressCellFocus={true}
          onCellDoubleClicked={handleCellDoubleClick}
          // onRowDoubleClicked={handleRowDoubleClick}
          onCellClicked={handleCellClick}
          isExternalFilterPresent={isExternalFilterPresent}
          doesExternalFilterPass={doesExternalFilterPass}
          overlayNoRowsTemplate='No Documents To Show'
          overlayLoadingTemplate='No Documents To Show'
          onRowDataUpdated={() => {
            handleScrollSizeChange();
          }}
          onGridSizeChanged={() => {
            handleScrollSizeChange();
          }}
          onFirstDataRendered={() => {
            handleScrollSizeChange();
          }}
          onColumnResized={handleColResize}
          // rowDragEntireRow={selectTabInfo?.default}
          // suppressMoveWhenRowDragging={selectTabInfo?.default}
          // onRowSelected={handleRowSelection}
          // onCellClicked={onCellClicked}
          // suppressRowDeselection={false}
          // suppressRowClickSelection={suppressCellSelection}
        ></AgGridReact>
      </div>
    </>
  );
}
export default Table;
