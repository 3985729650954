import { arrayMove } from "@dnd-kit/sortable";
import _ from "lodash";
import { Item, ItemParams } from "react-contexify";
import "react-contexify/dist/ReactContexify.css";
import { toast } from "react-toastify";
import { TAB_ACTIONS } from "../../../common/constants";
import { ClientEntity } from "../../../common/types/EntityTypes";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  selectClients,
  setClients,
  updateClientOrder,
} from "../../../redux/reducers/metadataSlice";
import { selectMenuTriggor } from "../../../redux/reducers/popupSlice";

import "react-toastify/dist/ReactToastify.css";

type TabActionProps = { tabId: string };

function TabAction() {
  const tabActionTriggor = useAppSelector(selectMenuTriggor);
  const dispatch = useAppDispatch();
  const clients: ClientEntity[] = useAppSelector(selectClients);
  const currentClient = clients.find((c) => c.id == tabActionTriggor);
  const currentClientIndex = _.findIndex(clients, {
    id: tabActionTriggor,
  });

  function handleItemClick({
    id,
    event,
    props,
    data,
    triggerEvent,
  }: ItemParams<TabActionProps>) {
    let tempClients = [...clients];
    const oldIndex = _.findIndex(tempClients, {
      id: tabActionTriggor,
    });

    if (id == TAB_ACTIONS.MOVE_LEFT) {
      const oldTab = { ...tempClients[currentClientIndex] };
      const newTab = { ...tempClients[currentClientIndex - 1] };

      const oldViewOrder = oldTab.viewOrder;
      oldTab.viewOrder = newTab.viewOrder;
      newTab.viewOrder = oldViewOrder;

      tempClients[currentClientIndex] = oldTab;
      tempClients[currentClientIndex - 1] = newTab;

      dispatch(
        updateClientOrder(
          arrayMove(tempClients, currentClientIndex, currentClientIndex - 1)
        )
      );
    } else if (id == TAB_ACTIONS.MOVE_RIGHT) {
      const oldTab = { ...tempClients[currentClientIndex] };
      const newTab = { ...tempClients[currentClientIndex + 1] };

      const oldViewOrder = oldTab.viewOrder;
      oldTab.viewOrder = newTab.viewOrder;
      newTab.viewOrder = oldViewOrder;

      tempClients[currentClientIndex] = oldTab;
      tempClients[currentClientIndex + 1] = newTab;

      dispatch(
        updateClientOrder(
          arrayMove(tempClients, currentClientIndex, currentClientIndex + 1)
        )
      );
    } else if (id == TAB_ACTIONS.DELETE) {
      if (tempClients[currentClientIndex].default) {
        toast.error(
          "Not Allowed. " +
            tempClients[currentClientIndex].name +
            " is DEFAULT Client."
        );
      } else {
        tempClients.splice(currentClientIndex, 1);
        dispatch(setClients(tempClients));
      }
    }
  }
  return (
    <section>
      {Object.entries(TAB_ACTIONS)
        .filter(([key, value]) => {
          if (value == TAB_ACTIONS.SET_DEFAULT || value == TAB_ACTIONS.RENAME)
            return false;

          if (currentClientIndex == 0) {
            if (value == TAB_ACTIONS.MOVE_LEFT) return false;
          } else if (currentClientIndex == clients.length - 1) {
            if (value == TAB_ACTIONS.MOVE_RIGHT) return false;
          }
          if (currentClient?.default) {
            if (value == TAB_ACTIONS.DELETE) return false;
            else if (value == TAB_ACTIONS.SET_DEFAULT) return false;
          }
          return true;
        })
        .map(([key, value], index) => {
          return (
            <Item
              key={key}
              id={value}
              data={{ value }}
              onClick={handleItemClick}
            >
              <span className="menu-status-text">{value}</span>
            </Item>
          );
        })}
    </section>
  );
}
export default TabAction;
