import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { RootState } from "../store";

export interface CTypeServer {
  id: number;
  code: string;
  name: string;
  order: number;
  isMultiLine: boolean;
}

export interface CType {
  id: number;
  title: string;
  key: string;
  order: number;
  isMultiLine: boolean;
}

export interface VType {
  id: number;
  name: string;
  key: string;
  order: number;
  value: string;
  prevValue: string;
}

export interface DType {
  id: string;
  name: string;
  notes: string;
  parentId?: string;
  state: string;
  values: VType[];
  timestamp: string;
  hide: boolean;
  market: string;
  client: string;
  author: string;
}

export interface DatatableState {
  columnValues: DType[];
  columnChildrenValues: DType[];
  parentsWithChildren: string[];
  newAddedRowId: string;
  isNewRow: Boolean;
  deletedDocument: any;
}

const initialState: DatatableState = {
  columnValues: [],
  columnChildrenValues: [],
  parentsWithChildren: [],
  newAddedRowId: "",
  isNewRow: false,
  deletedDocument: null,
};

export const documentsSlice = createSlice({
  name: "documents",
  initialState: initialState,
  reducers: {
    setParentsWithChildren: (state, value) => {
      return {
        ...state,
        parentsWithChildren: value.payload,
      };
    },
    setNewRow: (state, value) => {
      return {
        ...state,
        newAddedRowId: value.payload,
      };
    },
    setIsNewRow: (state, value) => {
      return {
        ...state,
        isNewRow: value.payload,
      };
    },
    setColumnHeaders: (state, value) => {
      return {
        ...state,
        columnHeaders: value.payload,
      };
    },
    setColumnValues: (state, value) => {
      let cValues = [...state.columnValues];
      if (cValues.length == 0) {
        return {
          ...state,
          columnValues: value.payload,
        };
      } else {
        let isUpdated = false;
        value.payload.forEach((doc: DType) => {
          const index = _.findIndex(cValues, ["id", doc.id]);
          if (index == -1) {
            cValues = cValues.concat(doc);
            isUpdated = true;
          } else {
            if (!_.isEqual(cValues[index], doc)) {
              cValues[index] = doc;
              isUpdated = true;
            }
          }
        });
        if (isUpdated)
          return {
            ...state,
            columnValues: _.orderBy(cValues, "lastActionAt", "desc"),
          };
      }
    },
    toggleChildrenHiddenState: (state, value) => {
      state.columnValues.forEach((val, index) => {
        if (val.parentId == value.payload) {
          state.columnValues[index].hide = !state.columnValues[index].hide;
        }
        state.columnChildrenValues.forEach((cVal, index) => {
          if (cVal.id == val.id) {
            state.columnChildrenValues[index].hide =
              !state.columnChildrenValues[index].hide;
          }
        });
      });
    },
    addChildrenColumnValues: (state, value) => {
      const cValues = state.columnChildrenValues.slice();
      let ccValues = state.columnValues.slice();
      value.payload.forEach((child: DType) => {
        const parentIndex = ccValues.findIndex(
          (val) => val.id === child.parentId
        );
        if (parentIndex > 0) {
          const childIndex = ccValues.findIndex((val) => val.id === child.id);
          if (childIndex < 0) {
            child["hide"] = false;
            if (parentIndex == ccValues.length - 1) {
              ccValues.push(child);
            } else {
              ccValues.splice(parentIndex + 1, 0, child);
            }
          } else {
            ccValues[childIndex].hide = false;
          }
        }
        let isPresent = false;
        let childIndex = -1;
        cValues.forEach((val, index) => {
          if (val.id == child.id) {
            isPresent = true;
            childIndex = index;
          }
        });
        if (!isPresent) {
          cValues.push(child);
        } else {
          cValues[childIndex].hide = false;
        }
      });
      return {
        ...state,
        columnValues: ccValues,
        columnChildrenValues: cValues,
      };
    },
  },
  extraReducers(builder) {},
});

export const {
  setColumnHeaders,
  setColumnValues,
  addChildrenColumnValues,
  toggleChildrenHiddenState,
  setIsNewRow,
  setNewRow,
  setParentsWithChildren,
} = documentsSlice.actions;

export const selectColumnValues = (state: RootState) =>
  state.documents.columnValues;
export const selectChildrenColumnValues = (state: RootState) =>
  state.documents.columnChildrenValues;
export const selectNewAddedRow = (state: RootState) =>
  state.documents.newAddedRowId;
export const selectIsNewRow = (state: RootState) => state.documents.isNewRow;
export const selectDeletedDocument = (state: RootState) => state.documents.deletedDocument;
export const selectParentsWithChildren = (state: RootState) => state.documents.parentsWithChildren;

export default documentsSlice.reducer;
