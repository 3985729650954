import { Menu } from "react-contexify";
import "react-tooltip/dist/react-tooltip.css";
import {
  AUTHOR_MENU_ID,
  CONTEXT_MENU_ID,
  MARKET_MENU_ID,
  NONE_MENU_ID,
  STATUS_FILTER_MENU_ID,
  STATUS_MENU_ID,
  TAB_ACTION_MENU_ID,
  TAB_VIEW_MENU_ID,
} from "../../common/constants";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { selectMenu, setMenu } from "../../redux/reducers/popupSlice";
import Author from "../popup/author/Author";
import LeaseFilter from "../popup/filter/LeaseFilter";
import LeaseStatus from "../popup/lease-status/LeaseStatus";
import Market from "../popup/market/Market";
import TabAction from "../popup/tab-action/TabAction";
import TabList from "../popup/tab-list/TabList";
import "./content.scss";
import TabContainer from "./tab/tab-container";
import Table from "./table/Table";
import { selectIsAppInitialized } from "../../redux/reducers/appSlice";
// import CustomTable from "./table/CustomTable";
import RowContextMenu from '../popup/row-context-menu/RowContextMenu';

function Content() {
  const selectedMenu = useAppSelector(selectMenu);
  const isAppInitialized = useAppSelector(selectIsAppInitialized);
  const dispatch = useAppDispatch();

  return (
    <div className="content-container">
      {isAppInitialized && <Table></Table>}
      {/* {isAppInitialized && <CustomTable></CustomTable>} */}
      {isAppInitialized && <TabContainer></TabContainer>}
      <Menu
        id={selectedMenu}
        theme="menu"
        animation="scale"
        onVisibilityChange={(isVisible: boolean) => {
          if (!isVisible) {
            dispatch(setMenu(NONE_MENU_ID));
          }
        }}
      >
        {selectedMenu == STATUS_MENU_ID && (
          <LeaseStatus key={STATUS_MENU_ID}></LeaseStatus>
        )}
        {selectedMenu == MARKET_MENU_ID && (
          <Market key={MARKET_MENU_ID}></Market>
        )}
        {selectedMenu == AUTHOR_MENU_ID && (
          <Author key={AUTHOR_MENU_ID}></Author>
        )}
        {selectedMenu == STATUS_FILTER_MENU_ID && (
          <LeaseFilter key={STATUS_FILTER_MENU_ID}></LeaseFilter>
        )}
        {selectedMenu == TAB_ACTION_MENU_ID && (
          <TabAction key={TAB_ACTION_MENU_ID}></TabAction>
        )}
        {selectedMenu == TAB_VIEW_MENU_ID && (
          <TabList key={TAB_VIEW_MENU_ID}></TabList>
        )}
        {selectedMenu == CONTEXT_MENU_ID && (
          <RowContextMenu key={CONTEXT_MENU_ID}></RowContextMenu>
        )}
      </Menu>
    </div>
  );
}
export default Content;
